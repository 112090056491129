import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import VideoPreview from "components/ui/base/VideoPreview";
import FullWidthImage from "components/ui/base/FullWidthImage";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import { IGatsbyImageData } from "gatsby-plugin-image";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";

interface TeamReportsPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: TeamReportsPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.team-reports.seo.title")}
        description={t("pages.team-reports.seo.description")}
        image={{
          relativePath: "meta/chat-team-reports.jpg",
          alt: "Chat team reports",
        }}
      />

      <PlainHeaderWithVideo
        title={t("pages.team-reports.title")}
        description={t("pages.team-reports.description")}
        titleMaxWidth={800}
        descriptionMaxWidth={700}
        ctaRegister={{ show: true }}
        video={{
          path: "pages/team-reports/team-insights.mp4",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Team Reports",
            path: "/team-reports/",
          },
        ]}
      />

      <VideoPreview
        id={"chat-reports"}
        title={t("pages.team-reports.1.title")}
        paragraph={t("pages.team-reports.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "pages/team-reports/live-chat-reports-new-conversations.mp4",
          },
        ]}
      />

      <FullWidthImage
        id={"agent-reports"}
        title={t("pages.team-reports.2.title")}
        description={t("pages.team-reports.2.subtitle")}
        image={{
          relativePath:
            "pages/team-reports/team-chat-reports-with-avg-replies.jpg",
          alt: t("pages.team-reports.2.title"),
        }}
      />

      <ImageWithDescription
        id={"help-center-reports"}
        title={t("pages.team-reports.3.title")}
        description={t("pages.team-reports.3.subtitle")}
        image={{
          relativePath: "pages/team-reports/help-center-article-reports.jpg",
          alt: t("pages.team-reports.3.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: "/help-center/",
          text: t("pages.team-reports.3.cta"),
        }}
      />

      <TestimonialWithImageFrancesca />

      <VideoPreview
        id={"failed-searches-reports"}
        title={t("pages.team-reports.4.title")}
        paragraph={t("pages.team-reports.4.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "pages/help-center/help-center-failed-searches.mp4",
          },
        ]}
        cta={{
          link: "/help-center/",
          text: t("pages.team-reports.4.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.team-reports.5.title")}
        description={t("pages.team-reports.5.subtitle")}
        image={{
          relativePath: "pages/team-reports/reports-csat.jpg",
          alt: t("pages.team-reports.5.title"),
          position: "left",
          fullWidth: true,
        }}
        cta={{
          link: "/surveys/",
          text: t("pages.team-reports.5.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.team-reports.6.title")}
        description={t("pages.team-reports.6.subtitle")}
        id={"screen-share"}
        image={{
          relativePath: "pages/team-reports/nps-votes-reports.jpg",
          alt: t("pages.team-reports.6.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: "/net-promoter-score/",
          text: t("pages.team-reports.6.cta"),
        }}
      />

      <FeatureGrid
        title={t("pages.team-reports.others.title")}
        centered={false}
        sections={[
          {
            title: t("pages.team-reports.others.helpCenter.title"),
            description: t("pages.team-reports.others.helpCenter.description"),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t("pages.team-reports.others.helpCenter.cta"),
                path: "/help-center/",
              },
            ],
          },
          {
            title: t("pages.team-reports.others.surveys.title"),
            description: t("pages.team-reports.others.surveys.description"),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t("pages.team-reports.others.surveys.cta"),
                path: "/surveys/",
              },
            ],
          },
          {
            title: t("pages.team-reports.others.funnels.title"),
            description: t("pages.team-reports.others.funnels.description"),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.team-reports.others.funnels.cta"),
                path: "/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const teamReportsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/chat-team-reports.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
